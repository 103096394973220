import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isInStock } from 'ui/util/gql-select-cart-items';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ItemName } from './item-name';
import { ItemRemove } from './item-remove';
import styles from './style.css';

interface IItemHeaderProps {
    item: ICartItemProps;
}

export const ItemHeader: React.FC<IItemHeaderProps> = ({
    item,
}) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.headWrap}>
            <ItemName item={item} />
            {
                (!isInStock(item)) && (
                    <span className={styles.oosNotification}>
                        {t('Product is no longer available, please remove from cart')}
                    </span>
                )
            }
            {item.userCanEdit && (
                <ItemRemove item={item} trans={t} />
            )}
        </p>
    );
};
