import React from 'react';
import { RawSpan } from 'ui/component/raw/span';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ItemPrice } from 'ui/component/cart/cart-with-items/line-items/items/line-item/item-price';
import optionStyles from 'ui/component/cart/cart-with-items/line-items/items/line-item/option-style.css';
import styles from './style.css';

export const CustomizationItem: React.FC<any> = ({ item }) => {
    const t = usePhraseTranslater();
    const customizableOptions = item?.customizable_options || [];
    return (
        <li data-test={`customization-${item.name}`} className={styles.customizationListItem}>
            <div className={styles.cartItemCustomizationCnt}>
                <div className={styles.customizationNameSkuCnt}>
                    <div className={styles.nameSkuContainer}>
                        <span>{item.name}</span>
                        {' '}
                        <span>{item.product?.sku}</span>
                    </div>
                    <div className={styles.optionLabelQty}>
                        {t('Qty: %1', item.quantity)}
                    </div>
                </div>
                <ItemPrice price={item.prices?.row_total?.value * item.quantity} className={styles.embroideryPrice} before="+" />
            </div>
            <ul className={optionStyles.options}>
                {customizableOptions.map((o: {label: string; values: [any]}) => (
                    <li key={o.label}>
                        <span className={optionStyles.optionLabel}>
                            {o.label}
                            :
                        </span>
                        {' '}
                        <RawSpan data-test={`embroidery-option-value-${o.values?.[0]?.value}`}>{o.values?.[0]?.value}</RawSpan>
                    </li>
                ))}
            </ul>
        </li>
    );
};
