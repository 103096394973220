import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { GET_REWARDS_INFO } from 'graphql/rewards/rewards';
import { IRewardsInfo } from 'ui/component/cart/cart-interface';
import styles from './style.css';

export const CartRewardsMessage: React.FC = () => {
    const [rewards, setRewards] = useState('');
    const [GetRewardsInfo] = useLazyQuery<{ getRewardsInfo: IRewardsInfo }>(GET_REWARDS_INFO, {
        onError: () => {
            // prevents console log
        },
        onCompleted: (data) => {
            const message = data.getRewardsInfo.notification_message;
            setRewards(message);
        },
    });
    useEffect(() => {
        GetRewardsInfo();
    }, []);
    /* The product description can contain HTML, so we need to
use dangerouslySetInnerHTML. This doesn't introduce an XSS
vulnerability because it can only be set by CW employees */

    /* eslint-disable react/no-danger */
    return (
        <div
            data-test="loyalty_message_display"
            dangerouslySetInnerHTML={{
                __html: rewards,
            }}
            className={styles.cartPageRewardsMessages}
            id="cartPageRewardsMessages"
        />
    );
};
