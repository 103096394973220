import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { ProductConfiguratorModal } from 'ui/component/product-configurator-modal';
import { connectQueryString } from '@silkpwa/module/react-component/connect-query-string';
import { routerStateKey } from '@silkpwa/module/react-component/router-state-key';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';
import { getStyle } from '../../get-style';

interface ItemProps {
    item: ICartItemProps;
    queryString?: { 'item_id': number };
    stateKey?: number;
}

const EditItemImpl: React.FC<ItemProps> = ({ item, queryString, stateKey }) => {
    const t = usePhraseTranslater();
    return (
        <>
            {useIsPunchoutInspectOperation() ? '' : (
                <ProductConfiguratorModal
                    open={item.id === queryString?.item_id}
                    productId={item.product.id}
                    itemId={item.id}
                    key={stateKey}
                    isCart
                >
                    {({ setOpen }) => (
                        <AccessibleButton
                            tag="span"
                            data-test="edit-btn"
                            className={styles.editText}
                            style={getStyle(item)}
                            action={() => setOpen(true)}
                        >
                            {t('edit')}
                        </AccessibleButton>
                    )}
                </ProductConfiguratorModal>
            )}
        </>
    );
};

export const EditItem = routerStateKey(connectQueryString(EditItemImpl));
