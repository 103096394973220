import React from 'react';
import { useGetCartTotalPrice, useGetRewardsTotal } from 'ui/util/get-cart-total-price';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ICartSummaryData } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface ICartTotalProps {
    cartSummaryData: ICartSummaryData;
}
export const CartTotal: React.FC<ICartTotalProps> = ({ cartSummaryData }) => {
    const getCartTotalPrice = useGetCartTotalPrice();
    const getRewardsTotal = useGetRewardsTotal();
    const t = usePhraseTranslater();
    const rewardsTotal = getRewardsTotal(cartSummaryData);
    /* eslint-disable react/no-danger */
    return (
        <p className={styles.totalBlock}>
            <span className={styles.total}>
                { t('TOTAL:') }
            </span>
            <span className={classes(styles.priceTotal, 'weglot-exclude')}>{ getCartTotalPrice(cartSummaryData) }</span>
            <span className={styles.rewardsTotal}>
                <span dangerouslySetInnerHTML={{ __html: rewardsTotal }} />
            </span>
        </p>
    );
};
