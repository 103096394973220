import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { OVERRIDE_CATEGORY_ID_OPTION_TYPE } from 'ui/util/override-categories/id-param-resolver';
import { RawSpan } from 'ui/component/raw/span';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';
import optionStyles from '../../option-style.css';

interface ItemConfigurableOptionsProps {
    item: ICartItemProps;
}

export const ItemConfigurableOptions: React.FC<ItemConfigurableOptionsProps> = ({ item }) => {
    const configurableOptions = item?.configurable_options ?? [];
    const bundleOptions = item?.bundle_options ?? [];
    if (configurableOptions.length === 0 && bundleOptions.length === 0) {
        return null;
    }

    return (
        <ul className={classes(optionStyles.options, styles.productInfo)}>
            {configurableOptions.map(option => (
                <li
                    className={classes(
                        styles.configurableOption,
                        option.__typename === OVERRIDE_CATEGORY_ID_OPTION_TYPE ? styles.hidden : '',
                    )}
                    data-test={`option-${option.option_code}`}
                    key={option.option_code}
                >
                    <span>
                        {`${option.option_label}: `}
                    </span>
                    <RawSpan>{option.value_label}</RawSpan>
                </li>
            ))}
            {bundleOptions.map((option, index) => (
                <li key={index.toString()} className={styles.bundleOption}>
                    {option?.values && option.values.length > 0 && (
                        <ul>
                            {option.values.map(variant => (
                                <li key={variant.sku} className={styles.bundleOption}>
                                    {`${variant.quantity} X ${variant.sku}`}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
};
