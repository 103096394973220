import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const ContinueShopping = (_: any) => {
    const t = usePhraseTranslater();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();

    return isPunchoutInspectOperation ? null : (
        <p className={styles.continueShopping}>
            <a className={styles.linkshopping} href="/">{t('continue shopping')}</a>
        </p>
    );
};
