import React, { useEffect } from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ICartPageProps } from 'ui/page/cart-page';
import { CartWithItems } from './cart-with-items';
import { EmptyCart } from './empty-cart';
// TODO: START: NEED TO REMOVE THIS CODE
import { CartRewardsMessage } from './cart-rewards-message';
import { InitCustomerSession } from '../../page/checkout-page/checkout-state';
// TODO: END: NEED TO REMOVE THIS CODE
import styles from './style.css';

export const Cart: React.FC<ICartPageProps> = ({
    cartItems,
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
    getCartList,
}) => {
    let isLoyaltyEnabled = false;
    const config = useConfig();
    if (config.extension_attributes) {
        isLoyaltyEnabled = config.extension_attributes.is_loyalty_program_enabled || false;
    }
    // TODO: START: NEED TO REMOVE THIS CODE
    useEffect(() => {
        const initializeUserData = async () => {
            await InitCustomerSession(false);
        };
        if (getCartList) {
            initializeUserData().then(() => getCartList());
        }
    }, []);
    // TODO: END: NEED TO REMOVE THIS CODE
    return (
        <div className={styles.cart}>
            {isLoyaltyEnabled && cartItems.length > 0 && (
                <CartRewardsMessage />
            )}
            {cartItems.length > 0 ? (
                <CartWithItems
                    cartItems={cartItems}
                    cartSummaryData={cartSummaryData}
                    cartCheckoutDisabled={cartCheckoutDisabled}
                    cartFreeShippingProgressBar={cartFreeShippingProgressBar}
                />
            ) : (
                <EmptyCart />
            )}
        </div>
    );
};
