import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import deleteIcon from 'assets/images/mockImgs/trashicon.png';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemRemoveProps {
    item: ICartItemProps;
    trans: (arg: string) => string;
}

export const ItemRemove: React.FC<IItemRemoveProps> = ({ item, trans }) => (
    <>
        {useIsPunchoutInspectOperation() ? '' : (
            <AccessibleButton
                tag="span"
                className={styles.deleteIcon}
                action={() => {
                    item.remove();
                }}
            >
                <img
                    src={deleteIcon}
                    data-test="delete-item"
                    title={trans('Remove')}
                    alt={trans('Remove')}
                />

            </AccessibleButton>
        )}
    </>
);
