import React from 'react';
import DOMPurify from 'dompurify';
import { RawSpan } from 'ui/component/raw/span';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps, ICustomizableOptions } from 'ui/component/cart/cart-interface';
import styles from './style.css';
import { ItemPrice } from '../../item-price';
import optionStyles from '../../option-style.css';

interface IEmbroideryItemProps {
    item: ICartItemProps;
}

export const EmbroideryItem: React.FC<IEmbroideryItemProps> = ({ item }) => {
    const t = usePhraseTranslater();
    const getFilteredValues = (data: any): string => {
        const values = data.map((item: any) => item.value);
        return values.join(', ');
    };
    const convertToSnakeCase = (str: string): string => str
        .toLowerCase().replace(/\s+/g, '_');
    const price = parseFloat(String(item.prices?.row_total?.value || 0));
    const customizableOptions = item.customizable_options || [];
    return (
        <li data-test={`embroidery-option-${item.name}`} className={styles.embroideryItem}>
            <div className={styles.embroideryQtyPriceWrapper}>
                <div className={styles.embroideryQuantity}>
                    <span className={styles.embroideryName}>{item.name}</span>
                    {(item.name && typeof item.name === 'string' && item.name.toLowerCase() !== 'special instructions') && (
                        <span className={styles.embroideryQty}>
                            {t('Qty: %1', item.quantity)}
                        </span>
                    )}
                </div>
                {(item.name && typeof item.name === 'string' && item.name.toLowerCase() !== 'special instructions') &&
                    <ItemPrice price={price * item.quantity} before="+" className={styles.embroideryPrice} />}
            </div>
            <ul className={optionStyles.options}>
                {customizableOptions.map((o: ICustomizableOptions) => {
                    if (o.option_code === '') {
                        return null;
                    }
                    if (o.option_code === 'embroidered_flags') {
                        return (
                            <li key={convertToSnakeCase(o.label)}>
                                {o.values?.map(v => (
                                    <RawSpan data-test={`embroidery-option-value-${convertToSnakeCase(o.label)}`}>{DOMPurify.sanitize(v.value)}</RawSpan>
                                ))}
                            </li>
                        );
                    }
                    if (o.option_code === 'special_instruction') {
                        return (
                            <li key={convertToSnakeCase(o.label)}>
                                {o.values?.map(v => <RawSpan data-test={`embroidery-option-value-${convertToSnakeCase(o.label)}`}>{v.value}</RawSpan>)}
                            </li>
                        );
                    }
                    return (
                        <li key={o.label}>
                            <span className={optionStyles.optionLabel}>
                                {o.label}
                                :
                            </span>
                            {' '}
                            <RawSpan data-test={`embroidery-option-value-${convertToSnakeCase(o.label)}`}>{getFilteredValues(o.values)}</RawSpan>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
};
