import React from 'react';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemNameProps {
    item: ICartItemProps;
}
export const ItemName: React.FC<IItemNameProps> = ({ item }) => {
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const config = useConfig();
    const getUrl = (url: string) => `${config.store_config?.current_store?.url}${url}`;

    const name = (
        <span
            className={styles.name}
            data-test={`cart-item ${item.product.sku}`}
        >
            {item.name}
        </span>
    );
    return item.product.url_key
        ? <a href={isPunchoutInspectOperation ? window.location.href : getUrl(item.product.url_key)}>{name}</a>
        : name;
};
