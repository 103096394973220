import { gql, TypedDocumentNode } from '@apollo/client';

interface IMiniCartData {
    amount: string;
    visible: boolean;
}

interface IRewards {
    getRewardsInfo: {
        'mini_cart_data': IMiniCartData;
        'notification_message': string;
        'show_block_cart': boolean;
        'show_message_cart': boolean;
    };
}

export const GET_REWARDS_INFO: TypedDocumentNode<IRewards> = gql`
    query GetRewardsInfo {
        getRewardsInfo {
            mini_cart_data {
                amount
                visible
            }
            notification_message
            show_block_cart
            show_message_cart
        }
    }
`;
