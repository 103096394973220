import React, { useMemo } from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import EmbroideryIcon from 'assets/images/mockImgs/EmbroideryIcon.png';
import closedArrow from 'assets/images/mockImgs/rightArrow.png';
import openedArrow from 'assets/images/mockImgs/downArrow.png';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import ExpandableList from './expandable-list';
import { EmbroideryItem } from './embroidery-item';
import { LogosItem } from './logos-item';
import { CustomizationItem } from './customization-item';
import { ItemPrice } from '../item-price';
import styles from './style.css';

interface IEmbroideryItemProps {
    item: ICartItemProps;
}
export const EmbroideryItems: React.FC<IEmbroideryItemProps> = ({ item }) => {
    const embroideryItems = item?.embroidery_items || [];
    const {
        customizationItems,
        alterationItems,
        printScreenItems,
        specialInstructionItem,
    } = useMemo(() => {
        const customizationItems: React.JSX.Element[] = [];
        const alterationItems: React.JSX.Element[] = [];
        const printScreenItems: React.JSX.Element[] = [];
        let specialInstructionItem: React.JSX.Element | null = null;
        embroideryItems.forEach((i) => {
            const lowerCaseName = i.name.toLowerCase();
            if (i.cw_alternation_type) {
                if (i.cw_alternation_type.includes('alteration')) {
                    alterationItems.push(<CustomizationItem key={i.id} item={i} />);
                } else if (i.cw_alternation_type === 'print_screen') {
                    printScreenItems.push(<CustomizationItem key={i.id} item={i} />);
                } else {
                    customizationItems.push(<CustomizationItem key={i.id} item={i} />);
                }
                return;
            }

            if (lowerCaseName === 'special instructions') {
                specialInstructionItem = <EmbroideryItem key={i.id} item={i} />;
                return;
            }

            if (lowerCaseName === 'embroidered logos') {
                customizationItems.push(<LogosItem key={i.id} item={i} />);
                return;
            }

            customizationItems.push(<EmbroideryItem key={i.id} item={i} />);
        });

        return {
            customizationItems,
            alterationItems,
            printScreenItems,
            specialInstructionItem,
        };
    }, [embroideryItems]);

    if (embroideryItems.length === 0) return null;

    return (
        <UseState initialState={false}>
            {([opened, setOpened]: [boolean, (state: boolean) => void]) => (
                <div className={styles.cartItemFoot}>
                    <AccessibleButton
                        tag="div"
                        className={styles.footExpand}
                        data-test={`product-options-${item?.product?.sku}`}
                        action={() => setOpened(!opened)}
                    >
                        <div className={styles.labelIconWrapper}>
                            <img
                                className={styles.EmbroideryIcon}
                                src={EmbroideryIcon}
                                alt="With Embroidery"
                            />
                            <span className={styles.expandText}>Product Options</span>
                        </div>
                        <div className={styles.priceIconWrapper}>
                            <ItemPrice
                                price={item.embroideryPrice}
                                before="+"
                                className={styles.expandPrice}
                            />
                            <div className={styles.arrowWrapper}>
                                <img
                                    className={opened ? styles.openedArrow : styles.closedArrow}
                                    src={opened ? openedArrow : closedArrow}
                                    alt=""
                                />
                            </div>
                        </div>
                    </AccessibleButton>
                    {opened && (
                        <ul
                            data-test={`product-options-list-${item?.product?.sku}`}
                            className={styles.embroideryItems}
                        >
                            {(customizationItems.length ||
                                alterationItems.length ||
                                printScreenItems.length) && (
                                <li>
                                    <ul
                                        data-test={`customization-list-${item?.product?.sku}`}
                                        className={styles.customizationCnt}
                                    >
                                        <ExpandableList
                                            title="Alteration"
                                            items={alterationItems}
                                            styles={styles}
                                        />
                                        <ExpandableList
                                            title="Print Screen"
                                            items={printScreenItems}
                                            styles={styles}
                                        />
                                        {customizationItems}
                                    </ul>
                                </li>
                            )}
                            {specialInstructionItem}
                        </ul>
                    )}
                </div>
            )}
        </UseState>
    );
};
