import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const ItemCount = ({ items }) => {
    const t = usePhraseTranslater();
    const num = items.reduce((total, item) => total + item.quantity, 0);
    const label = num > 1 ? t('ITEMS') : t('ITEM');

    return (
        <span>
            <span data-test="cart-count">{num}</span>
            {' '}
            {label}
        </span>
    );
};
