import React from 'react';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';
import { ItemHeader } from './item-header';
import { ItemSKU } from './item-sku';
import { ItemLoyaltyMessage } from './item-loyalty-message';
import { ItemConfigurableOptions } from './item-configurable-options';
import { EditItem } from './edit-item';
import { ItemQuantity } from './item-quantity';
import { ItemPrice } from '../item-price';
import { ItemGiftCardDetails } from './item-giftcard-details';

interface IItemInformationProps {
    item: ICartItemProps;
}
export const ItemInformation: React.FC<IItemInformationProps> = ({ item }) => {
    const price = parseFloat(String(item.prices?.row_total?.value || 0));
    return (
        <div className={styles.rightBlock}>
            <ItemHeader item={item} />
            <ItemSKU item={item} />
            <ItemLoyaltyMessage item={item} />
            <ItemConfigurableOptions item={item} />
            <ItemGiftCardDetails item={item} />
            <div className={item.userCanEdit ? styles.editArea : styles.disableArea}>
                <EditItem item={item} />
                <div className={styles.productQtyPriceWrapper}>
                    <ItemPrice price={price} testAttribute="item-price" className={styles.productPrice} />
                    <ItemQuantity item={item} />
                </div>
            </div>
        </div>
    );
};
