import React from 'react';
import { CartQuantity } from 'ui/component/cart-quantity';
import { keys } from '@silkpwa/module/util/keys';
import { QuantityMessage } from 'ui/component/quantity-message';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const QuantityBox = ({ item }) => {
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const canEditQuantity = item.userCanEdit && !isPunchoutInspectOperation;
    return (
        <CartQuantity
            qty={item.quantity}
            remove={item.remove}
            setQty={item.setQty}
            simpleProductId={item.simpleProductId}
        >
            {({
                  increment,
                  decrement,
                  qty,
                  setQty,
                  applyUpdate,
                  reset,
                  simpleProduct,
              }) => (
                  <div className={styles.quantityWrap}>
                      <div className={styles.quantityBox}>
                          <div className={styles.quantity}>
                              {canEditQuantity ? (
                                  <button
                                      data-test="btn-qty-decr"
                                      className={!item.userCanEdit ? styles.decrDisable : styles.decrEnable}
                                      onClick={() => {
                                        decrement();
                                    }}
                                      type="button"
                                  >
                                    -
                                  </button>
                            ) : ''}
                              <input
                                  data-test="qty-input"
                                  type="text"
                                  value={qty}
                                  className={styles.inputQty}
                                  disabled={!canEditQuantity}
                                  onChange={(e) => {
                                    setQty(e.target.value);
                                }}
                                  onKeyUp={(e) => {
                                    if (e.keyCode === keys.ENTER) applyUpdate();
                                    else if (e.keyCode === keys.ESCAPE) reset();
                                }}
                                  onBlur={applyUpdate}
                                  onClick={e => (e.target as HTMLInputElement).select()}
                              />
                              {canEditQuantity ? (
                                  <button
                                      data-test="btn-qty-incr"
                                      className={!item.userCanEdit ? styles.incrDisable : styles.incrEnable}
                                      onClick={() => {
                                        increment();
                                    }}
                                      type="button"
                                  >
                                    +
                                  </button>
                            ) : ''}
                          </div>
                      </div>
                      <QuantityMessage
                          quantity={qty}
                          product={simpleProduct.originalProduct}
                          className={styles.quantityMessage}
                      />
                  </div>
            )}
        </CartQuantity>
    );
};
