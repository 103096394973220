import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import DOMPurify from 'dompurify';
import { RawSpan } from 'ui/component/raw/span';
import { ICartItemProps, ICustomizableOptions } from 'ui/component/cart/cart-interface';
import { ItemPrice } from '../../item-price';
import optionStyles from '../../option-style.css';
import styles from '../embroidery-item/style.css';

interface LogoDetails {
    logo: {
        label: string;
        value: string;
    };
    place?: {
        label: string;
        value: string;
    };
    type: 'img' | 'text';
}

interface LogosItemProps {
    item: ICartItemProps;
}

export const LogosItem: React.FC<LogosItemProps> = ({ item }) => {
    const t = usePhraseTranslater();

    const getFilteredValues = (item: ICartItemProps): LogoDetails[] => {
        const output: LogoDetails[] = [];
        const customizableOptions = item.customizable_options || [];
        const customizableOptionsMap = new Map<string, any>();
        customizableOptions.forEach((option) => {
            if (option.label) {
                customizableOptionsMap.set(option.label, option);
            }
        });

        const createLogoDetails = (logo: ICustomizableOptions, type: 'img' | 'text'): LogoDetails => {
            const logoNumber = logo.label.split(' ')[1];
            const placeLabel = t('Logo %1 Placement', logoNumber);
            const newLabel = t('Logo %1', logoNumber);
            const place = customizableOptionsMap.get(placeLabel);

            const logoDetails: LogoDetails = {
                logo: {
                    label: logo.label,
                    value: logo.values?.[0]?.value || '',
                },
                type,
            };

            if (place) {
                logoDetails.place = {
                    label: newLabel,
                    value: place.values?.[0]?.value || '',
                };
            }

            return logoDetails;
        };

        customizableOptions.forEach((logo: ICustomizableOptions) => {
            if (logo.label?.includes('Logo')) {
                if (logo.label.includes('(new)')) {
                    output.push(createLogoDetails(logo, 'img'));
                } else if (logo.label.includes('(onfile)')) {
                    output.push(createLogoDetails(logo, 'text'));
                }
            }
        });

        output.sort((a, b) => {
            const aLabel = a.place?.label ?? '';
            const bLabel = b.place?.label ?? '';
            return aLabel.localeCompare(bLabel);
        });

        return output;
    };

    const itemRowTotal = item?.prices?.row_total?.value || 0;

    return (
        <li data-test={`embroidery-option-${item.name}`} className={styles.embroideryItem}>
            <div className={styles.embroideryQtyPriceWrapper}>
                <div className={styles.embroideryQuantity}>
                    <span className={styles.embroideryName}>{item.name}</span>
                    <span className={styles.embroideryQty}>
                        {t('Qty: %1', item.quantity)}
                    </span>
                </div>
                <ItemPrice price={itemRowTotal * item.quantity} before="+" className={styles.embroideryPrice} />
            </div>
            <ul className={optionStyles.options}>
                {getFilteredValues(item).map(o => (
                    <li className={styles.listOption} key={o.logo.label}>
                        <span>
                            {`${o.place?.label}:${o.place?.value}`}
                        </span>
                        {o.type === 'img' ? (
                            <RawSpan data-test="embroidery-option-value">{DOMPurify.sanitize(o.logo.value)}</RawSpan>
                        ) : (
                            <span>{o.logo.value || ''}</span>
                        )}
                    </li>
                ))}
            </ul>
        </li>
    );
};
