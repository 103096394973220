import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import DOMPurify from 'dompurify';
import { RawSpan } from 'ui/component/raw/span';
import { ICartSummaryData } from 'ui/component/cart/cart-interface';
import { generateKey } from 'ui/util/generate-key';
import styles from './style.css';
import { getValue } from './util';

interface ISummaryListProps {
    cartSummaryData: ICartSummaryData;
}

export const SummaryList: React.FC<ISummaryListProps> = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const subTotal = cartSummaryData.subtotal_excluding_tax;
    const grandTotal = cartSummaryData.grand_total;
    const taxTotal = cartSummaryData.tax_total?.amount?.value ? cartSummaryData.tax_total?.amount : null;
    const shippingAmount = cartSummaryData.shipping_charge?.amount?.value
        ? cartSummaryData.shipping_charge?.amount : null;
    const discounts = cartSummaryData.discounts ?? [];

    return (
        <div className={styles.labelWrap}>
            <p className={styles.summary} key="subtotal_excluding_tax">
                <span className={styles.summaryLabel}>{t('Subtotal')}</span>
                <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, subTotal)}</span>
            </p>
            {discounts.map((discount: { label: any; amount: { value: any } }, index) => (
                <p className={styles.summary} key={generateKey([index], 'discount')}>
                    <RawSpan className={styles.discountWrapper} data-test="discount-label">{DOMPurify.sanitize(discount.label)}</RawSpan>
                    <span
                        className={classes(styles.price, 'weglot-exclude')}
                    >
                        {getValue(formatPrice, discount?.amount)}
                    </span>
                </p>
            ))}
            {shippingAmount && (
                <p className={styles.summary} key="shipping_charge">
                    <span className={styles.summaryLabel}>{t('Shipping & Handling')}</span>
                    <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, shippingAmount)}</span>
                </p>
            )}
            {taxTotal && (
                <p className={styles.summary} key="tax_total">
                    <span className={styles.summaryLabel}>{t('Tax')}</span>
                    <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, taxTotal)}</span>
                </p>
            )}
            <p className={styles.summary} key="grand_total">
                <span className={styles.summaryLabel}>{t('Grand Total')}</span>
                <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, grandTotal)}</span>
            </p>
        </div>
    );
};
