import React from 'react';
import { connectCart } from 'ui/util/gql-connect-cart';
import { Cart } from 'ui/component/cart';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps, ICartSummaryData } from 'ui/component/cart/cart-interface';
import { classes } from '@silkpwa/module/util/classes';
import Styles from 'ui/component/main-column/style.css';

export interface ICartPageProps {
    cartItems: Array<ICartItemProps>;
    cartSummaryData: ICartSummaryData;
    cartCheckoutDisabled: boolean;
    cartFreeShippingProgressBar: string;
    getCartList?: () => void;
    isCartOpen?: boolean;
    isCartLoading?: boolean;
}

const CartPageImpl: React.FC<ICartPageProps> = ({
    cartItems,
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
    getCartList,
}) => {
    const t = usePhraseTranslater();
    const designConfig = useDesignConfig();
    const documentTitle = `${designConfig.pageTitle} ${t('Cart')}`;
    return (
        <div data-page-type="cart" className={Styles.cartPage}>
            <div className={classes(Styles.cartPageColumnWidth, 'maincolumn-cartpage')}>
                <DocumentTitle>{documentTitle}</DocumentTitle>
                <Cart
                    getCartList={getCartList}
                    cartItems={cartItems}
                    cartSummaryData={cartSummaryData}
                    cartCheckoutDisabled={cartCheckoutDisabled}
                    cartFreeShippingProgressBar={cartFreeShippingProgressBar}
                />
                <CertonaRecommendations />
            </div>
        </div>
    );
};

const CartPage = connectCart(CartPageImpl);

export { CartPage as default };
