import React, { FC, useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

interface ExpandableListProps {
    title: string;
    items: React.JSX.Element[];
    styles: { [key: string]: string };
    length?: number;
}

const ExpandableList: FC<ExpandableListProps> = ({
    title,
    items,
    styles,
    length = 4,
}) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(prev => !prev);
    const t = usePhraseTranslater();
    const itemsToShow = showMore ? items : items.slice(0, length);

    if (items.length === 0) return null;

    return (
        <li className={styles.customizationListItem}>
            <div className={styles.customizationName}>
                {t(title)}
                <span className={styles.customizationItemContCnt}>
                    {` ( ${items.length} ${items.length > 1 ? t('items') : t('item')} )`}
                </span>
            </div>
            <div className={styles.altPrintCnt}>
                {itemsToShow.map(item => item)}
            </div>
            {items.length > length && (
                <div className={styles.addMoreCnt}>
                    <AccessibleButton
                        className={styles.addMoreLinkCnt}
                        action={toggleShowMore}
                        tag="a"
                    >
                        {showMore ? t('Show Less') : t('Show More')}
                    </AccessibleButton>
                </div>
            )}
        </li>
    );
};

export default ExpandableList;
