import React from 'react';
import { getProductSkuValue } from 'ui/util/get-product-info';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ItemImage } from './item-image';
import { ItemInformation } from './item-information';
import { EmbroideryItems } from './embroidery-items';
import styles from './style.css';

interface ILineItemProps {
    item: ICartItemProps;
}

export const LineItem: React.FC<ILineItemProps> = ({ item }) => (
    <li key={item?.product?.id} data-test={`cart-item-${getProductSkuValue(item)}`} className={styles.lineItem}>
        <ItemImage item={item} />
        <ItemInformation item={item} />
        <EmbroideryItems item={item} />
    </li>
);
